import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import LeftArrow from "../../../../assets/images/icons/back.png";
import Spribe1 from "../../../../assets/images/spribe/Aviator.webp";
import Spribe2 from "../../../../assets/images/spribe/Dice.webp";
import Spribe3 from "../../../../assets/images/spribe/Goal.webp";
import Spribe4 from "../../../../assets/images/spribe/Hilo.webp";
import Spribe5 from "../../../../assets/images/spribe/Mine.webp";
import Spribe6 from "../../../../assets/images/spribe/MiniROulette.webp";
import { addMetaTags, openGraphTags } from "../../../../utils/addMetaTags";
import LeftBarSports from "../../../components/LeftBarSports";

const Spribe = () => {
  const navigate = useNavigate();

  useEffect(() => {
    updateMetaTags();
  }, []);

  const updateMetaTags = () => {
    addMetaTags({
      title: "Betrich - Arcade Games",
      description:
        "Betrich is a crypto-accepting platform offering a premier experience in online sports betting, casino gaming, and E-sports. Join Betrich to enjoy live sports events, innovative casino games with fast withdrawals, secure transactions, and safe gambling practices. Sign up today on Betrich.io – your trusted crypto gambling platform for modern sports betting and live casino gaming.",
      keywords:
        "Initial coin offering through betting, Betrich, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, crypto gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino, Betrich popular games, Betting Platform, Safe Gambling, Trusted Betting Site, Betrich.io, Popular Betting site",
      metaUrl: "https://betrich.io/Spribe",
    });
    openGraphTags({
      ogTitle: "Betrich - Arcade Games",
      ogDescription:
        " Betrich is a crypto-accepting platform offering a premier experience in online sports betting, casino gaming, and E-sports. Join Betrich to enjoy live sports events, innovative casino games with fast withdrawals, secure transactions, and safe gambling practices. Sign up today on Betrich.io – your trusted crypto gambling platform for modern sports betting and live casino gaming.",
      ogUrl: "https://betrich.io/Spribe",
      ogType: "website",
      ogKeywords:
        "Initial coin offering through betting, Betrich, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, crypto gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino, Betrich popular games, Betting Platform, Safe Gambling, Trusted Betting Site, Betrich.io, Popular Betting site",
    });
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="main-page">
          <LeftBarSports />

          <div className="main-content">
            <div className="container-fluid">
              <div className="spribe-page">
                <div className="headingSec">
                  <div className="back-link" onClick={() => navigate(-1)}>
                    <img src={LeftArrow} alt="LeftArrow" /> <h1>Spribe</h1>
                  </div>
                </div>

                <div className="games-list">
                  <ul>
                    <li>
                      <a href="/casino/spribe/aviator">
                        <img src={Spribe1} alt="Live casino game" />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/dice">
                        <img src={Spribe2} alt="Live casino game" />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/goal">
                        <img src={Spribe3} alt="Live casino game" />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/hilo">
                        <img src={Spribe4} alt="Live casino game" />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/mines">
                        <img src={Spribe5} alt="Live casino game" />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/miniroulette">
                        <img src={Spribe6} alt="Live casino game" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </main>
    </>
  );
};

export default Spribe;
