import React, { useEffect } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import LeftArrow from "../../../../assets/images/icons/back.png";
import { Container } from "react-bootstrap";
import LeftBarSports from "../../../components/LeftBarSports";
import TawkChat from "../../../components/TawkChat";
import WhatsAppWidget from "../../../components/WhatsAppWidget";
import LiveChatImg from "../../../../assets/images/live-chat.png";
import WhatsappChat from "../../../../assets/images/Whatsapp.svg";
import Footer from "../../../../containers/Footer";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    console.log("item", item);
    dispatch(setSelectedGateway(item));
    navigate(`/deposit/${item.code}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="main-page">
          <LeftBarSports />

          <div className="main-content">
            <Container fluid>
              <div className="headingSec">
                <div className="back-link" onClick={() => navigate(-1)}>
                  <img src={LeftArrow} alt="LeftArrow" /> <h1>Deposit</h1>{" "}
                </div>
              </div>

              <div className="gatewaySection">
                <div className="gatewaypart">
                  <Tabs
                    defaultActiveKey="Deposit"
                    id="uncontrolled-tab-example"
                    className=""
                  >
                    <Tab
                      eventKey="Deposit"
                      title="Gateway Deposit"
                      className="deposit_class"
                    >
                      <div
                        className="gatewaySection"
                        style={{
                          maxWidth: "inherit",
                          margin: "0",
                          width: "100%",
                        }}
                      >
                        <div className="gatewaypart">
                          {/* <Tabs
                        defaultActiveKey="crypto"
                        id="uncontrolled-tab-example"
                        className=""
                      >
                      <Tab
                        eventKey="crypto"
                        title="Crypto"
                        className="deposit_class"
                      > */}
                          <div className="auto_deposit">
                            <div className="manual_heading ">
                              <strong>Auto UPI Deposit</strong>{" "}
                              {/* <span>(Crypto & Fiat)</span> */}
                            </div>
                            <div className="cards">
                              {gatewaysList?.deposits
                                ?.sort((a, b) => a.order - b.order)
                                ?.filter((f) => f.code === "nitro")
                                .map((item, index) => {
                                  return (
                                    <div
                                      className="cards1"
                                      onClick={() => {
                                        handleGatewayClick(item);
                                      }}
                                      key={index}
                                    >
                                      <div className="card-icon">
                                        <img src={item?.logo || DepositImg} />
                                      </div>

                                      <p>{item?.name}</p>
                                    </div>
                                  );
                                })}
                              {/* </Tab> */}
                              {/* <Tab
                            eventKey="bank"
                            title="Bank Transfer"
                            className="bank_deposit_class"
                          > */}
                            </div>
                          </div>
                          <div className="auto_deposit">
                            <div className="manual_heading mt-0">
                              <strong>Online Deposit</strong>{" "}
                              <span>(Crypto & Fiat)</span>
                            </div>
                            <div className="cards">
                              {gatewaysList?.deposits
                                ?.sort((a, b) => a.order - b.order)
                                ?.filter(
                                  (f) => f.type === "auto" && f.code !== "nitro"
                                )
                                .map((item, index) => {
                                  return (
                                    <div
                                      className="cards1"
                                      onClick={() => {
                                        handleGatewayClick(item);
                                      }}
                                      key={index}
                                    >
                                      <div className="card-icon">
                                        <img src={item?.logo || DepositImg} />
                                      </div>

                                      <p>{item?.name}</p>
                                    </div>
                                  );
                                })}
                              {/* </Tab> */}
                              {/* <Tab
                            eventKey="bank"
                            title="Bank Transfer"
                            className="bank_deposit_class"
                          > */}
                            </div>
                          </div>

                          {
                            <div className="manual_deposit">
                              <div className="manual_heading">
                                <strong>Manual Transfer</strong>{" "}
                                <span>(Bank Transfer, UPI & QR Payments)</span>
                              </div>

                              <div className="cards">
                                {bankDetails && (
                                  <div
                                    className="cards1"
                                    onClick={() => {
                                      handleBankTransferClick();
                                    }}
                                  >
                                    {" "}
                                    <div className="card-icon">
                                      <img
                                        src={bankImg}
                                        style={{ width: "66px" }}
                                      />
                                    </div>
                                    <p>{"Bank Transfer"}</p>
                                  </div>
                                )}

                                {gatewaysList?.deposits
                                  ?.sort((a, b) => a.order - b.order)
                                  ?.filter((f) => f.type === "manual")
                                  .map((item, index) => {
                                    return (
                                      <div
                                        className="cards1"
                                        onClick={() => {
                                          handleGatewayClick(item);
                                        }}
                                        key={index}
                                      >
                                        <div className="card-icon">
                                          <img src={item?.logo || DepositImg} />
                                        </div>
                                        <p>{item?.name}</p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          }
                          {/* </Tab>
                      </Tabs> */}
                        </div>
                      </div>
                      {/* <div className="info-text">
                    <p className="note mt-5">
                      <span>Note:</span> : In case you face any issue with payment
                      methods <b> Contact Us</b> <br /> If you don’t find any
                      particular payment method that you want to use. Go to{" "}
                      <b>Chat deposit</b>
                    </p>

                    <ul className="contact">
                      <li>
                        <a
                          className="wtsp"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                        >
                          Whatsapp
                        </a>
                      </li>
                      <li>
                        <a className="cntct" href="/contactus">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div> */}
                    </Tab>
                    {/* <Tab
                  eventKey="bank"
                  title="Bank Transfer"
                  className="bank_deposit_class"
                >
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={DepositImg} />
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab> */}
                    <Tab
                      eventKey="manual"
                      title="Chat Deposit"
                      className="manual_deposit"
                    >
                      <div className="cards1">
                        <a href="/contactus">
                          <div className="card-icon">
                            <img src={LiveChatImg} />
                          </div>
                        </a>

                        <p>Deposit Using Live Chat</p>
                      </div>
                      <div className="cards1">
                        <a
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <div className="card-icon">
                            <img src={WhatsappChat} />
                          </div>
                        </a>

                        <p>Deposit Using Whatsapp</p>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Container>

            <Footer />
          </div>
        </div>
      </main>

      <TawkChat />
      <WhatsAppWidget />
    </>
  );
};
export default GatewaysList;
