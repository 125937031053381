import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import LeftArrow from "../../../../assets/images/icons/back.png";

// import { CasinoGames } from "../../../../lib/CasinoGames";
import { SlotGames } from "../../../../lib/data/SlotGames.data";
import CasinoCard from "../../../components/CasinoCard";
import SearchIcon from "../../../../assets/images/icons/magnifying-glass-search.png";
import { useNavigate } from "react-router-dom";
import { addMetaTags, openGraphTags } from "../../../../utils/addMetaTags";
import LeftBarSports from "../../../components/LeftBarSports";

const Netent = () => {
  const categoriesTab = [
    { name: "Netent", key: "netent" },
    // { name: "RedTiger", key: "redTiger" },
    // { name: "Qtech", key: "qtech", redirectUrl: "/casino/qtech" },
    // { name: "Vivo", key: "vivo", redirectUrl: "/casino/vivo" },
  ];
  const [activeCategory, setActiveCategory] = useState("netent");
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    updateMetaTags();
  }, []);

  const updateMetaTags = () => {
    addMetaTags({
      title: "Betrich - Slots Games",
      description:
        "Betrich is a crypto-accepting platform offering a premier experience in online sports betting, casino gaming, and E-sports. Join Betrich to enjoy live sports events, innovative casino games with fast withdrawals, secure transactions, and safe gambling practices. Sign up today on Betrich.io – your trusted crypto gambling platform for modern sports betting and live casino gaming.",
      keywords:
        "Initial coin offering through betting, Betrich, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, crypto gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino, Betrich popular games, Betting Platform, Safe Gambling, Trusted Betting Site, Betrich.io, Popular Betting site",
      metaUrl: "https://betrich.io/Slots",
    });
    openGraphTags({
      ogTitle: "Betrich - Slots Games",
      ogDescription:
        " Betrich is a crypto-accepting platform offering a premier experience in online sports betting, casino gaming, and E-sports. Join Betrich to enjoy live sports events, innovative casino games with fast withdrawals, secure transactions, and safe gambling practices. Sign up today on Betrich.io – your trusted crypto gambling platform for modern sports betting and live casino gaming.",
      ogUrl: "https://betrich.io/Slots",
      ogType: "website",
      ogKeywords:
        "Initial coin offering through betting, Betrich, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, crypto gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino, Betrich popular games, Betting Platform, Safe Gambling, Trusted Betting Site, Betrich.io, Popular Betting site",
    });
  };

  const handleSearch = (event) => {
    const value = event.target.value;

    if (value) {
      setIsSearched(true);
      let filtered = [];
      Object.values(SlotGames)
        .filter((f) => f?.key === activeCategory)
        .map((slide) => {
          Object.values(slide)
            .filter((f) => typeof f !== "string")
            .map((game) => {
              let v = Object.values(game).filter(
                (f) => f?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1
              );
              let clonedArray = [...filtered];
              clonedArray = [...filtered, ...v];
              filtered = clonedArray;
            });
        });
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setIsSearched(false);
    }
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="main-page">
          <LeftBarSports />

          <div className="main-content">
            <div className="container-fluid">
              <div className="slots-sectionPage MobileGamesSection">
                <div className="games-section">
                  <div className="headingSec">
                    <div className="back-link" onClick={() => navigate(-1)}>
                      <img src={LeftArrow} alt="LeftArrow" /> <h1>Slots</h1>
                    </div>
                  </div>

                  <div className="SlotsTabsSection">
                    <ul>
                      {categoriesTab.map((tab, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setFilteredData([]);
                            setIsSearched(false);
                            if (tab?.redirectUrl) {
                              navigate(tab?.redirectUrl);
                            } else {
                              setActiveCategory(tab.key);
                            }
                          }}
                          style={
                            activeCategory === tab.key
                              ? {
                                  background: "#222430",
                                }
                              : {}
                          }
                        >
                          <div
                            href="#"
                            style={
                              activeCategory === tab.key
                                ? {
                                    color: "#ffb80c",
                                  }
                                : {}
                            }
                          >
                            {tab.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="SearchBar">
                    <img src={SearchIcon} alt="search" className="search" />
                    <input
                      type="text"
                      placeholder="Search Games"
                      onChange={handleSearch}
                    />
                  </div>

                  {!isSearched ? (
                    <ul>
                      {Array.isArray(Object.values(SlotGames)) &&
                        Object.values(SlotGames)
                          .filter((f) => f?.key === activeCategory)
                          .map((slide) =>
                            Object.values(slide)
                              .filter((f) => typeof f !== "string")
                              .map((game) =>
                                Object.values(game).map((item, idx) => (
                                  <CasinoCard
                                    key={"casinogame" + idx}
                                    data={item}
                                  />
                                ))
                              )
                          )}
                    </ul>
                  ) : (
                    <ul>
                      {(filteredData.length &&
                        filteredData.map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))) ||
                        null}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </main>
    </>
  );
};

export default Netent;
