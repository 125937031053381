import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { FaSpinner } from "react-icons/fa";
import Close from "./../../assets/images/icons/left-menu/close.svg";
import Exchange from "./../../assets/images/icons/left-menu/exchange.svg";
import Sportsbook from "./../../assets/images/icons/left-menu/sportsbook.svg";
import Casino from "./../../assets/images/icons/left-menu/casino.svg";
import LiveCasino from "./../../assets/images/icons/left-menu/live-casino.svg";
import TeenPatti from "./../../assets/images/icons/left-menu/TeenPatti.png";
import Aviator from "./../../assets/images/icons/left-menu/aviator.svg";
import Esports from "./../../assets/images/icons/left-menu/e-sports.png";
import DragonTiger from "./../../assets/images/icons/left-menu/DragonTiger.png";
import LiveSupport from "./../../assets/images/icons/left-menu/live-support.svg";
import QuickGames from "./../../assets/images/icons/left-menu/arcade-games.svg";
import BecomeAnAffiliate from "./../../assets/images/icons/left-menu/become-an-affiliate.svg";
import ArrowRight from "./../../assets/images/icons/left-menu/arrow-right.svg";
import Roulette from "./../../assets/images/icons/left-menu/roulette.png";
import AndarBahar from "./../../assets/images/icons/left-menu/andarbahar.png";
import LightningDice from "./../../assets/images/icons/left-menu/lightningdice.png";
import BlackJack1 from "./../../assets/images/icons/left-menu/blackjack.png";
import UltimateSicBo from "./../../assets/images/icons/left-menu/ultimatesicbo.png";
import Strike777 from "./../../assets/images/icons/left-menu/strike777.png";
import Monopoly from "./../../assets/images/icons/left-menu/monopoly.png";
import HiLo from "./../../assets/images/icons/left-menu/hilo.png";
import StockMarket from "./../../assets/images/icons/left-menu/stockmarket.png";
import BalloonRace from "./../../assets/images/icons/left-menu/ballonrace.png";
import Miniroulette from "./../../assets/images/icons/left-menu/miniroulette.png";
import Keno from "./../../assets/images/icons/left-menu/keno.png";
import Goal from "./../../assets/images/icons/left-menu/goal.png";
import Cricket from "./../../assets/images/icons/left-menu/Cricket.png";
import Home from "./../../assets/images/icons/left-menu/Home.png";
import Balloon from "./../../assets/images/icons/left-menu/balloon.svg";
import PlinkoX from "./../../assets/images/icons/left-menu/PlinkoX.svg";
import JetX from "./../../assets/images/icons/left-menu/JetX.svg";
import FishingFortune from "./../../assets/images/icons/left-menu/FishingFortune.svg";

import Accordion from "react-bootstrap/Accordion";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const htmlElement = document.documentElement;
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const handleLeftBarClick = () => {
    // htmlElement.style.overflow = "auto";
    document.body.classList.remove("leftbar-opened");
  };

  return (
    <>
      <div className="leftbarSec">
        <div className="top-head d-md-none">
          <a href="/home">
            {appDetails?.LOGO_URL ? (
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            ) : (
              <FaSpinner />
            )}
          </a>

          <button
            type="button"
            className="close-btn"
            onClick={handleLeftBarClick}
          >
            <img src={Close} alt="close" />
          </button>
        </div>

        <div className="links games_link">
          <ul>
            <li>
              <a href="/">
                <img src={Home} alt="exchange" />
                <span>Home</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  {" "}
                  <img src={QuickGames} alt="exchange" />
                  <span>Quick Games</span>
                  <img src={ArrowRight} alt="arrow right" className="arrow" />
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <a href="/casino/spribe/aviator">
                        <img src={Aviator} alt="exchange" />
                        <span>Aviator</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/qtechsmartsoft-jetx-u5599">
                        <img src={JetX} alt="monopoly" />
                        <span>JetX</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/qtechsmartsoft-plinkox-u6950">
                        <img src={PlinkoX} alt="monopoly" />
                        <span>PlinkoX</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-dragon-tiger">
                        <img src={DragonTiger} alt="exchange" />
                        <span>Dragon Tiger</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-teen-patti-3-card">
                        <img src={TeenPatti} alt="exchange" />
                        <span>Teen Patti 3 Card</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-italian-roulette">
                        <img src={Roulette} alt="exchange" />
                        <span>Roulette</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-andar-bahar">
                        <img src={AndarBahar} alt="exchange" />
                        <span>Andar Bahar</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-andar-bahar">
                        <img src={BlackJack1} alt="exchange" />
                        <span>Blackjack</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezgevo-lightning-dice">
                        <img src={LightningDice} alt="lightning dice" />
                        <span>Lightning Dice</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezg-ultimate-sic-bo">
                        <img src={UltimateSicBo} alt="ultimate sic bo" />
                        <span>Ultimate Sic Bo</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezgrt-777-strike">
                        <img src={Strike777} alt="777 strike" />
                        <span>777 Strike</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezgevo-monopoly-big-baller">
                        <img src={Monopoly} alt="monopoly" />
                        <span>Monopoly</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/qt-fishing-fortune">
                        <img src={FishingFortune} alt="monopoly" />
                        <span>Fishing Fortune</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>

                    <li>
                      <a href="/casino/ezgevo-gonzos-fp-hilo">
                        <img src={HiLo} alt="hi lo" />
                        <span>Hi Lo</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezgevo-stock-market">
                        <img src={StockMarket} alt="stock market" />
                        <span>Stock Market</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/ezgevo-gonzos-balloon-race">
                        <img src={BalloonRace} alt="balloon race" />
                        <span>Balloon Race</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/qtechsmartsoft-balloon">
                        <img src={Balloon} alt="monopoly" />
                        <span>Ballon</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/miniroulette">
                        <img src={Miniroulette} alt="bottom head link icon" />
                        <span>Mini Roulette</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/keno">
                        <img src={Keno} alt="keno" />
                        <span>Keno</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/casino/spribe/goal">
                        <img src={Goal} alt="goal" />
                        <span>Goal</span>
                        <img
                          src={ArrowRight}
                          alt="arrow right"
                          className="arrow"
                        />
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <li>
              <a href="/sports/Cricket">
                <img src={Cricket} alt="exchange" />
                <span>Cricket</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/sports/Inplay">
                <img src={Exchange} alt="exchange" />
                <span>Exchange</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/atlas-lobby">
                <img src={Sportsbook} alt="sportsbook" />
                <span>Sportsbook</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <img src={Casino} alt="casino" />
                <span>Casino</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
            <li>
              <a href="/casino/list">
                <img src={LiveCasino} alt="live casino" />
                <span>Live Casino</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>

            <li>
              <a href="/sports/Inplay">
                <img src={Esports} alt="esports" />
                <span>Esports</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
          </ul>
        </div>

        <div className="links others_link">
          <ul>
            <li>
              <a href="/contactus">
                <img src={LiveSupport} alt="live support" />
                <span>Live Support</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>

            <li>
              <a href="https://affiliate.betrich.io">
                <img src={BecomeAnAffiliate} alt="become an affiliate" />
                <span>Become an Affiliate</span>
                <img src={ArrowRight} alt="arrow right" className="arrow" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LeftBarSports;
