import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import MenuIcon from "../assets/images/icons/menu-toggle.svg";
import { useSelector } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";
import { AiOutlineClose } from "react-icons/ai";
import { getNotifications } from "../redux/app/actions";
import { FaSpinner } from "react-icons/fa";
import OtpLessUi from "../views/components/OtpLessForm";

const Header = ({ agentCode = "" }) => {
  const { appDetails } = useSelector((state) => state.app);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const [showOtpLessModal, setShowOtpLesModal] = useState(false);
  const location = useLocation();
  const isOtpLessPage = window.location.pathname === "/login/otpless";
  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    if (location.pathname === "/" && !isMobile) {
      document.body.classList.add("leftbar-opened");
    } else {
      document.body.classList.remove("leftbar-opened");
    }
  }, [location.pathname]);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);

    setDefaultModal(modalName);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleToggleChange = () => {
    const isClassExist = document.body.classList.contains("leftbar-opened");

    if (isClassExist) {
      document.body.classList.remove("leftbar-opened");
    } else {
      document.body.classList.add("leftbar-opened");
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee
            onMouseOver={(e) => e.target.stop()}
            onMouseOut={(e) => e.target.start()}
          >
            {websiteNotifications.map((notf, idx) => notf?.content + " ")}
          </marquee>
          <h2 onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </h2>
        </div>
      )) ||
        null}

      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="leftbar_toggle me-3">
                    <Button
                      variant="primary"
                      onClick={() => handleToggleChange((s) => !s)}
                    >
                      <img src={MenuIcon} alt="toggle menu" />
                    </Button>
                  </div>
                  <div className="logo">
                    <a href="/">
                      {appDetails?.LOGO_URL ? (
                        <img
                          src={appDetails?.LOGO_URL}
                          alt="Logo"
                          height={26}
                        />
                      ) : (
                        <FaSpinner />
                      )}
                    </a>
                  </div>

                  <div className="middle-part"></div>

                  <div className="headerRight">
                    {/* <ul className="social">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="whats app" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.FACEBOOK != ""
                              ? appDetails?.FACEBOOK
                              : "javascript:void(0)"
                          }
                          target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                        >
                          <img src={FBIcon} />
                        </a>
                      </li>
                    </ul> */}

                    {(!isOtpLessPage && (
                      <Button
                        onClick={() => {
                          setShowOtpLesModal(true);
                        }}
                        className=" btn_signup"
                      >
                        Sign up
                      </Button>
                    )) ||
                      null}
                    <Button
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                      className=" btn_signin ms-4"
                    >
                      Log in
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {!isOtpLessPage && (
          <OtpLessUi
            showOtpLessModal={showOtpLessModal}
            setShowOtpLesModal={setShowOtpLesModal}
            agentCode={agentCode}
          />
        )}

        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
            setShowOtpLesModal={setShowOtpLesModal}
          />
        )}
      </header>
    </>
  );
};

export default Header;
